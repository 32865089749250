<template>
  <div>
    <v-card
      class="d-flex flex-column align-center justify-center mx-auto px-2 py-12 mb-8"
      :color="statusColor"
      dark
      tile
    >
      <v-icon class="text-h2 mb-4">{{ statusIcon }}</v-icon>
      <div class="text-h5">{{ statusText }}</div>
    </v-card>
    <div class="d-flex flex-column align-center justify-center pa-8">
      <div class="text-h5 mb-3">支払情報</div>
      <div class="text-subtitle-1">支払方法：{{ paymentMethod }}</div>
      <div v-if="dataSource.status === 0" class="text-subtitle-1">
        支払期限: {{ dataSource.expires_at && $dayjs(dataSource.expires_at).format('YYYY-MM-DD HH:mm') }}
      </div>
      <div class="text-subtitle-1">金額：{{ dataSource.total_amount }} 円</div>
    </div>
    <div v-if="dataSource.status === 0" class="d-flex flex-column align-center justify-center pa-8">
      <v-btn class="mb-12" color="orange" dark x-large @click="handlePay">支払いに進む</v-btn>

      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <template v-slot:activator="{ on, attrs }">
              <v-btn plain x-small v-bind="attrs" v-on="on">キャンセル</v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h6 grey lighten-2 mb-6">キャンセル</v-card-title>
              <v-card-text>こちらのご注文をキャンセルしますか？</v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small color="primary" text @click="handleCancel">はい</v-btn>
                <v-btn small color="primary" text @click="dialog = false">いいえ</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>
    </div>
    <div v-if="dataSource.status === 1" class="d-flex flex-column align-center justify-center mt-8">
      <div class="pay-dataSourceult">ご注文ありがとうございます!</div>
    </div>
  </div>
</template>

<script>
  import Methods from '@/api/method.js';

  export default {
    name: 'RecoseleUserPaydataSourceult',

    data() {
      return {
        dialog: false,
        loading: false,
        dataSource: {
          // amount: 0,
          // payment_method: 0, // 0:PayPay決済,1:Stripe(クレジット決済)
          // category: 0, // 0:支払い、１:リファンド (以后还可能涉及分期付款)
          // status: 0, // 0:未払い、1:支払い済、2:キャンセル、3：返金済み
          // original_amount: 0,
          // total_amount: 0,
        },
      };
    },

    computed: {
      paymentMethod() {
        return ['PayPay決済', 'クレジット決済'][this.dataSource.payment_method];
      },
      statusText() {
        return ['支払い内容', '支払い済', 'キャンセル', '返金済み', '期限切れ'][
          this.dataSource.status
        ];
      },
      statusColor() {
        return ['orange', 'green', 'grey', 'red', 'grey'][this.dataSource.status];
      },
      statusIcon() {
        return [
          'mdi-credit-card-sync-outline',
          'mdi-credit-card-check-outline',
          'mdi-credit-card-off-outline',
          'mdi-credit-card-refund-outline',
          'mdi-credit-card-off-outline',
        ][this.dataSource.status];
      },
    },

    mounted() {
      const thatFetchPayment = () => this.fetchPayment(timer);
      const timer = setInterval(function () {
        setTimeout(async function () {
          thatFetchPayment();
        }, 0);
      }, 2000);
      thatFetchPayment();
      // 清除定时器
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer);
      });
    },

    methods: {
      async fetchPayment(timer) {
        try {
          this.loading = true;
          const payment_id = this.$route.params.payment_id;
          const { data } = await Methods.getPayment(payment_id);
          this.dataSource = data;
          this.loading = false;
          if ([1, 2, 3].includes(data.status)) {
            clearInterval(timer);
          }
        } catch (error) {
          console.log(error);
          clearInterval(timer);
          this.loading = false;
        }
      },
      async handlePay() {
        try {
          /* PayPayによる支払い */
          if (this.dataSource.payment_method === 0 && !!this.dataSource.deeplink) {
            window.location.href = this.dataSource.deeplink;
          } else if (this.dataSource.payment_method === 1 && this.dataSource.sessionId) {
            /* Stripeによる支払い */
            const stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY);
            await stripe.redirectToCheckout({
              sessionId: this.dataSource.sessionId,
            });
          } else {
            alert(
              '支払いのリンクの取得に失敗しました。お手数をおかけしますが、スタッフにご連絡ください🙇‍♀️',
            );
          }
        } catch (error) {
          alert(error?.message);
        }
      },
      async handleCancel() {
        try {
          const payment_id = this.$route.params.payment_id;
          await Methods.updatePayment({ payment_id, status: 2 });
          this.dialog = false;
        } catch (error) {
          alert(error?.message);
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import url('https://fonts.googleapis.com/css2?family=Rampart+One&display=swap');
  .pay-dataSourceult {
    font-size: 1.5rem !important;
    font-family: 'Rampart One', cursive !important;
    font-weight: 900 !important;
    color: #4caf50 !important;
    caret-color: #4caf50 !important;
  }
</style>
